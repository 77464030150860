import { Container, makeStyles, Typography } from "@material-ui/core";
import { ReactElement } from "react";
import PortfolioList from "../../components/PortfolioList";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 300,
    marginTop: "3rem",
    borderBottom: "solid 1px black",
    margin: "0 auto",
    paddingBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingTop: "5rem",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "2rem",
    },
  },
}));

export default function PortfolioPage(): ReactElement {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="lg">
        <Typography className={classes.title} align="center" variant="h2">
          Portfolio
        </Typography>
      </Container>
      <Container className={classes.container} maxWidth="lg">
        <PortfolioList />
      </Container>
    </>
  );
}
