const navLinks = [
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Buildings",
    link: "/agriculturalBuildings",
    subMenuItems: [
      {
        name: "Agricultural",
        link: "/agriculturalBuildings",
      },
      {
        name: "Commerical",
        link: "/commericalBuildings",
      },
      {
        name: "Groundworks",
        link: "/groundwork",
      },
    ],
  },
  {
    name: "Portfolio",
    link: "/portfolio",
  },
];

const contactEmail = "sales@fgagroup.co.uk";

const contactNumbers = [
  {
    name: "Julian Arrowsmith",
    text: "+44 (0) 7527 395424",
    tel: "tel:+447527395424",
  },
  {
    name: "Harry Groome",
    text: "+44 (0) 7805 419544",
    tel: "tel:+447805419544",
  },
];

export { navLinks, contactEmail, contactNumbers };
