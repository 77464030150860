import { makeStyles, Typography } from "@material-ui/core";
import { ReactElement } from "react";

interface Props {
  image: string;
  subtitle: string;
  large?: boolean;
  handleClick: (arg: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0.5rem 0",
    width: 400,
    height: 380,
    cursor: "pointer",
    transition: "all .4s ease-in",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
    [`& h6`]: {
      marginTop: "0.5rem",
      padding: "0 0.5rem",
    },
    ["& p"]: {
      marginTop: "0.5rem",
      fontWeight: 300,
      transition: "all .2s ease-in",
      padding: "0 0.5rem",
    },
    [`&:hover`]: {
      backgroundColor: "#eee",
      [`& p`]: {
        color: "#333",
      },
    },
  },
  rootLarger: {
    width: 600,
    height: 450,
    [theme.breakpoints.down("xs")]: {
      width: 300,
      height: "auto",
    },
  },
  image: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "70%",
    [theme.breakpoints.down("xs")]: {
      height: 320,
    },
  },
}));

export default function ImageWrapper({
  image,
  large,
  handleClick,
  subtitle,
}: Props): ReactElement {
  const classes = useStyles();

  return (
    <div
      onClick={() => handleClick(image)}
      className={large ? `${classes.root} ${classes.root}` : classes.root}
    >
      <div
        style={{ backgroundImage: `url("${image}")` }}
        className={classes.image}
      ></div>
      <Typography
        component="p"
        color="secondary"
        align="center"
        variant="subtitle2"
      >
        {subtitle}
      </Typography>
    </div>
  );
}
