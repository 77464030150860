import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import { Facebook, Instagram, LinkedIn, Menu } from "@material-ui/icons";
import { ReactElement, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "./logo.png";
import MenuWithSubMenu from "./menuWithSubMenu";
import MobileMenu from "./mobileMenu";
import { navLinks } from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    backgroundColor: theme.palette.secondary.dark,
    padding: "1rem 0",
  },
  logo: {
    width: 280,
    [theme.breakpoints.down("md")]: {
      width: 150,
    },
    [theme.breakpoints.down("sm")]: {
      width: 70,
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nav: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: 700,
    [`& p`]: {
      fontWeight: 300,
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
      },
    },
    [`& a`]: {
      textDecoration: "none",
      color: "inherit",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  socialContainer: {
    marginLeft: "2rem",
    [`& svg`]: {
      width: 35,
      height: 35,
      margin: "0.5rem",
      cursor: "pointer",
      [`&:hover`]: {
        color: theme.palette.primary.dark,
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  menuIcon: {
    display: "none",
    cursor: "pointer",
    width: 35,
    height: 35,
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
}));

export default function Header(): ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="xl">
        <Link to="/">
          <img className={classes.logo} src={logo} alt="fga group" />
        </Link>
        <div className={classes.rightContainer}>
          <div className={classes.nav}>
            {navLinks.map(({ name, link, subMenuItems }) => {
              if (subMenuItems) {
                return (
                  <MenuWithSubMenu
                    key={name}
                    name={name}
                    navItems={subMenuItems}
                    link={link}
                  />
                );
              }

              return (
                <Link key={name} to={link}>
                  <Typography component="p" variant="h6">
                    {name}
                  </Typography>
                </Link>
              );
            })}
            <Button
              onClick={() => history.push("/contact")}
              variant="outlined"
              color="primary"
            >
              Contact Us
            </Button>
          </div>
          <div className={classes.socialContainer}>
            <Facebook
              onClick={() =>
                window.open("https://www.facebook.com/FGAGroupLtd", "_blank")
              }
              color="primary"
            />
            <Instagram
              onClick={() =>
                window.open("https://www.instagram.com/fgagroupltd/", "_blank")
              }
              color="primary"
            />
            <LinkedIn
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/fga-group-ltd/",
                  "_blank"
                )
              }
              color="primary"
            />
          </div>
          <Menu
            onClick={() => setMobileMenuOpen(true)}
            className={classes.menuIcon}
          />
          {mobileMenuOpen && (
            <MobileMenu
              setMobileMenuOpen={setMobileMenuOpen}
              navLinks={navLinks}
            />
          )}
        </div>
      </Container>
    </div>
  );
}
