import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#faf9f8",
    },
    secondary: {
      main: "#555b60",
    },
  },
  typography: {
    fontFamily: "Poppins",
    h2: {
      fontWeight: "bold",
    },
  },
});

export default theme;
