import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";

interface Props {
  name: string;
  link: string;
  navItems: Array<{
    name: string;
    link: string;
  }>;
}

const useStyles = makeStyles((theme) => ({
  title: {
    cursor: "pointer",
  },
  subMenu: {
    position: "absolute",
    zIndex: 2,
    backgroundColor: theme.palette.secondary.main,
    padding: "0 1rem",
    [`& a`]: {
      padding: "1rem",
      [`&:hover`]: {
        [`& p`]: {
          color: theme.palette.primary.dark,
        },
      },
    },
  },
  listItem: {
    cursor: "pointer",
    [`&:hover`]: {
      color: theme.palette.primary.dark,
    },
  },
}));

export default function MenuWithSubMenu({
  name,
  link,
  navItems,
}: Props): ReactElement<Props> {
  const classes = useStyles();
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div
      onMouseEnter={() => setMenuOpen(true)}
      onMouseLeave={() => setMenuOpen(false)}
    >
      <Typography
        className={classes.title}
        onClick={() => {
          setMenuOpen(false);
          history.push(link);
        }}
        component="p"
        variant="h6"
      >
        {name}
      </Typography>
      {menuOpen && (
        <List className={classes.subMenu}>
          {navItems.map((item) => (
            <ListItem
              className={classes.listItem}
              onClick={() => {
                setMenuOpen(false);
                history.push(item.link);
              }}
              key={item.name}
            >
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}
