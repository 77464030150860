import { Container, makeStyles, Modal, Typography } from "@material-ui/core";
import { ReactElement, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { BuildingImages } from "../../types";
import ImageWrapper from "../ImageWrapper";

interface Props {
  buildingImages: BuildingImages[];
}

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: "3rem 0",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  imageContainerLarger: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  modalRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  carouselContainer: {
    width: "80vw",
  },
  carouselImage: {
    width: "100%",
    height: "70vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      height: "50vh",
    },
  },
  buildingImageContainer: {
    display: "flex",
    overflow: "scroll",
  },
  buildingImage: {
    width: "20vw",
    minWidth: 250,
    height: 200,
    backgroundSize: "cover",
    backgroundPosition: "center",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      minWidth: 150,
      height: 150,
    },
  },
  buildingImageOverlay: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.6)",
    transition: "all .2s ease-in",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [`& h6`]: {
      fontWeight: 300,
    },
    [`& h5`]: {
      fontWeight: 300,
      fontSize: "0.7rem",
      margin: "0.5rem",
    },
    [`& p`]: {
      color: "#DEFFFC",
      transition: "all .2s ease-in",
    },
    [`&:hover`]: {
      backgroundColor: "rgba(0,0,0,0.4)",
      [`& p`]: {
        color: "#eee",
      },
    },
  },
}));

export default function MultiGalleryCarousel({
  buildingImages,
}: Props): ReactElement {
  const [selectedBuilding, setSelectedBuilding] = useState<BuildingImages>();

  const classes = useStyles();
  const carouselRef = useRef<Carousel>(null);

  return (
    <>
      <Container
        className={
          buildingImages.length === 2
            ? `${classes.imageContainer} ${classes.imageContainerLarger}`
            : classes.imageContainer
        }
        maxWidth="lg"
      >
        {buildingImages.map((building, index) => (
          <ImageWrapper
            key={`${index}-image-wrapper`}
            subtitle={building.subtitle}
            image={building.imageUrls[0]}
            handleClick={() => setSelectedBuilding(building)}
            large={buildingImages.length === 2}
          />
        ))}
      </Container>
      <Modal
        open={Boolean(selectedBuilding)}
        onClose={() => setSelectedBuilding(undefined)}
        className={classes.modalRoot}
      >
        <div className={classes.carouselContainer}>
          <Carousel
            useKeyboardArrows
            infiniteLoop
            autoFocus
            showThumbs={false}
            showStatus={false}
            dynamicHeight={false}
            ref={carouselRef}
          >
            {selectedBuilding?.imageUrls.map((image, index) => (
              // TODO: Create multi image container
              <div
                key={`carousel-img-${index}`}
                className={classes.carouselImage}
                style={{ backgroundImage: `url("${image}")` }}
              ></div>
            ))}
          </Carousel>
          <div className={classes.buildingImageContainer}>
            {buildingImages.map((building, index) => (
              <div
                key={`building-img-${index}`}
                className={classes.buildingImage}
                style={{ backgroundImage: `url("${building.imageUrls[0]}")` }}
                onClick={() => {
                  // TODO: Figure out a fix to reset the starting image
                  setSelectedBuilding(building);
                  carouselRef.current?.forceFocus();
                }}
              >
                <div className={classes.buildingImageOverlay}>
                  <div>
                    <Typography
                      color="primary"
                      variant="subtitle1"
                      component="h5"
                      align="center"
                    >
                      {building.subtitle}
                    </Typography>
                    <Typography
                      component="p"
                      variant="subtitle2"
                      align="center"
                    >
                      View Images
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
}
