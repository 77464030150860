import { Button, Container, makeStyles } from "@material-ui/core";
import { ReactElement } from "react";
import ShowcaseBanner from "../../components/ShowcaseBanner";
import Banner from "../../components/Banner";
import HomePhoto from "../../components/HomePhoto";
import { useHistory } from "react-router-dom";

const secondBanner = {
  header:
    "Need a new Agricultural, Equestrian, Commercial or Industrial Building?",
  textContent: `We can construct anything for you, from a grain store to an industrial/commercial unit, a cattle shed or animal housing to a horticultural building, from barn conversions to a business premises.`,
  imageUrl:
    "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/building-banner.jpg",
  imageAlt: "agricultural building",
};

const useStyles = makeStyles((theme) => ({
  secondBannerButton: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
      margin: "1rem auto",
    },
  },
  lastBanner: {
    padding: "5rem 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
}));

export default function HomePage(): ReactElement {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <HomePhoto />
      <Container maxWidth="lg">
        <ShowcaseBanner
          {...secondBanner}
          buttons={
            <>
              <Button
                className={classes.secondBannerButton}
                color="secondary"
                onClick={() => history.push("/agriculturalBuildings")}
                variant="outlined"
                size="large"
              >
                Agricultural
              </Button>
              <Button
                className={classes.secondBannerButton}
                color="secondary"
                onClick={() => history.push("/commericalBuildings")}
                variant="outlined"
                size="large"
              >
                Commerical
              </Button>
            </>
          }
        />
      </Container>
      <Banner
        header=""
        backgroundImage="https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/banner2.jpg"
        button={<div className={classes.lastBanner}></div>}
      />
    </>
  );
}
