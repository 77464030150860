import { makeStyles, Typography } from "@material-ui/core";
import { ReactElement } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "90vh",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    backgroundImage:
      'url("https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/agricultural-building-banner.jpg")',
    [`& h3`]: {
      width: "80%",
      margin: "5rem auto 0 auto",
      fontSize: "4rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
  },
}));

export default function PhotoCarousel(): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography align="center" component="h3" color="primary" variant="h3">
        Steel Frame Buildings For Agricultural, Equestrian, Commercial &
        Industrial Sectors, A Northamptonshire business working across the UK
      </Typography>
    </div>
  );
}
