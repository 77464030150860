import { Container, makeStyles, Typography } from "@material-ui/core";
import { ReactElement } from "react";

interface Props {
  title: string;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 300,
    marginTop: "3rem",
    borderBottom: "solid 1px black",
    margin: "0 auto",
    paddingBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
}));

export default function TopTitle({ title }: Props): ReactElement<Props> {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Typography className={classes.title} align="center" variant="h2">
        {title}
      </Typography>
    </Container>
  );
}
