import TopBanner from "../../components/TopPhotoBanner";
import { ReactElement } from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import type { BuildingImages } from "../../types";
import MultiGalleryCarousel from "../../components/MultiGalleryCarousel";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "5rem 0",
    [theme.breakpoints.down("md")]: {
      padding: "1rem 2rem",
    },
  },
  title: {
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  subTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
}));

const buildingImages: BuildingImages[] = [
  {
    title: "Kier",
    subtitle: "42m x 18m x 6m Cattle Building in Leicestershire",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Keir/Image+(011).jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Keir/Image+(008).jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Keir/Image+(00D).jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Keir/Image+(00F).jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Keir/Image+(013).jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Keir/Image+(013).jpeg",
    ],
  },
  {
    title: "Gable",
    subtitle: "18m x 10m x 4m Gable Extension in Northamptonshire",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/gable/71ff31e6-cb12-409d-85cb-91b51dc6dc3d.JPG",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/gable/d9d78c50-35d2-4d2f-9c1a-c9f42fb6f006.JPG",
    ],
  },
  {
    title: "Bespoke Grain Store",
    subtitle: "48m x 24m x 7.5m Bespoke Grain Store in Northamptonshire",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/portfolio/items/portItem5.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/portfolio/items/portItem1.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/portfolio/items/portItem2.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/portfolio/items/portItem3.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/portfolio/items/portItem4.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/portfolio/items/portItem6.jpg",
    ],
  },
  {
    title: "Arrowsmith",
    subtitle: "42m x 24m x 7m Grain Store in Northamptonshire",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Arrowsmith/1.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Arrowsmith/2.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Arrowsmith/3.jpg",
    ],
  },
  {
    title: "Pocklington",
    subtitle: "48.8m x 20m x 7.3m Grain Store in Northamptonshire",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Pocklington/1.jpg",
      // "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Pocklington/2.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Pocklington/3.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Pocklington/4.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Pocklington/5.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Pocklington/6.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Pocklington/7.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Pocklington/8.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Pocklington/9.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Pocklington/10.jpg",
    ],
  },
  {
    title: "Barbsy",
    subtitle: "48m x 18m x 6m General Storage Building in Leicestershire",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Barbsy/1.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Barbsy/2.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Barbsy/3.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Barbsy/4.jpg",
    ],
  },
  {
    title: "Berridge",
    subtitle: "30m x 30m x 7m Manure Shed in Peterborough",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Berridge/1.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Berridge/2.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Berridge/3.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Berridge/4.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Berridge/5.jpg",
    ],
  },
  {
    title: "Hoby",
    subtitle: "18m x 10m x 3.5m Stable Block in Leicestershire",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Hoby/1.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Hoby/2.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Hoby/3.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Hoby/4.jpg",
    ],
  },
  {
    title: "Smith",
    subtitle: "28m x 22m x 5m Livestock Canopy in Northamptonshire",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Ed+Smith/Smith+1.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Ed+Smith/Smith+2.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Ed+Smith/Smith+3.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Ed+Smith/Smith+4.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Ed+Smith/Smith+5.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Ed+Smith/Smith+6.jpg",
    ],
  },
];

export default function AgriculturalBuildingPage(): ReactElement {
  const classes = useStyles();

  return (
    <>
      <TopBanner
        backgroundImage="https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Keir/Image+(011).jpeg"
        wideStyle
      />
      <Container className={classes.container} maxWidth="lg">
        <Typography className={classes.title} variant="h4">
          Agricultural Construction services backed by skilled craftsmanship and
          proven experience.
        </Typography>
        <Typography className={classes.subTitle} variant="subtitle1">
          We work with our clients throughout the project, assisting with the
          design process, helping with obtaining planning, providing full
          structural drawings, skilled craftsmanship throughout with only the
          highest quality sourced materials. From a lean-to on an existing
          building to a purpose-built grain store and everything in between.
          Drying floors with hard wood or soft wood flooring, centrifugal fans
          and fan housing, concrete panels, roller shutter, personnel doors and
          CHC burners are also things we specialise in.
        </Typography>
        <MultiGalleryCarousel buildingImages={buildingImages} />
      </Container>
    </>
  );
}
