interface PortfolioItem {
  headingImageUrl: string;
  title: string;
  textContent: string;
  galleryImages: Array<string>;
}

// TODO: Change to individual images
const galleryImages = [
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/grain-dryers.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/portfolio/items/portItem2.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/commercial-building2.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/portfolio/items/portItem4.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/portfolio/items/portItem5.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/portfolio/items/portItem6.jpg",
];

const portfolioItems: Array<PortfolioItem> = [
  {
    galleryImages,
    headingImageUrl:
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/grain-dryers.jpg",
    title: "Grain drying and cleaning plant",
    textContent:
      "This project was a year in the making. It’s no wonder when you consider all the plant features, which include: a 150 ton/hour intake pit, a 4 screen Zanin rotary cleaner, a 50 ton/hour Svegma dryer, two 150 ton wet bins, a 150 ton/hour conveyor belt and an automatic travelling cross conveyor to fill the grain store.",
  },
  {
    galleryImages,
    headingImageUrl:
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/portfolio/construction-project-management-sawbridge-08I5994.jpg",
    title: "Commercial office buildings",
    textContent:
      "For this client, we have been project managing a range of steel framed buildings with a brick skin finish. These are high spec commercial office blocks, designed to accommodate the customer’s growing commercial property rental business.",
  },
  {
    galleryImages,
    headingImageUrl:
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/portfolio/agricultural-construction-williams-08I5757.jpg",
    title: "Whole farm project",
    textContent:
      "Over a period of time, we have designed and built an entire farmyard for a longstanding customer. In fact we have designed and constructed a wide range of buildings to help aid the farmer’s growing enterprise: everything from grain drying floors, general purpose buildings and livestock buildings.",
  },
  {
    galleryImages,
    headingImageUrl:
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/grainDryerStore.jpeg",
    title: "Grain store with grain drying floor",
    textContent:
      "To design and build a new agricultural grain store building measuring 140ft x 70ft was the brief for this client. This includes a grain drying floor and all the associated equipment.",
  },
];

export { portfolioItems };
