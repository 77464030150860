import {
  Button,
  Collapse,
  Container,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Close, ExpandLess, ExpandMore } from "@material-ui/icons";
import { Dispatch, ReactElement, SetStateAction, useState } from "react";
import { useHistory } from "react-router-dom";

interface Props {
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
  navLinks: Array<{
    name: string;
    link: string;
    subMenuItems?: Array<{
      name: string;
      link: string;
    }>;
  }>;
}

interface NavItemProps {
  name: string;
  link: string;
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
}

interface CollapseNavProps {
  name: string;
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
  subMenuItems: Array<{
    name: string;
    link: string;
  }>;
}

const useStyles = makeStyles((theme) => ({
  mobileMenu: {
    position: "fixed",
    height: "100vh",
    width: "100vw",
    backgroundColor: theme.palette.secondary.main,
    top: 0,
    left: 0,
    [`& a`]: {
      textDecoration: "none",
      color: "inherit",
    },
    zIndex: 3,
  },
  close: {
    width: 50,
    height: 50,
    float: "right",
    marginRight: "1rem",
    marginTop: "1rem",
  },
  mobileNavContainer: {
    marginTop: 80,
  },
}));

function NavItem({
  name,
  setMobileMenuOpen,
  link,
}: NavItemProps): ReactElement<NavItemProps> {
  const history = useHistory();

  const handleClick = () => {
    history.push(link);
    setMobileMenuOpen(false);
  };

  return (
    <ListItem onClick={handleClick} button>
      <ListItemText primary={name} />
    </ListItem>
  );
}

function CollapseNavMenu({
  name,
  subMenuItems,
  setMobileMenuOpen,
}: CollapseNavProps): ReactElement<CollapseNavProps> {
  const [open, setOpen] = useState(false);

  const handleOpenClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem onClick={handleOpenClick} button>
        <ListItemText primary={name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subMenuItems.map((item) => (
            <NavItem
              key={`mobile-cap-${item.name}`}
              name={item.name}
              link={item.link}
              setMobileMenuOpen={setMobileMenuOpen}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default function MobileMenu({
  setMobileMenuOpen,
  navLinks,
}: Props): ReactElement<Props> {
  const classes = useStyles();
  const history = useHistory();

  const handleContactClick = () => {
    history.push("/contact");
    setMobileMenuOpen(false);
  };

  return (
    <div className={classes.mobileMenu}>
      <Close
        className={classes.close}
        color="primary"
        onClick={() => setMobileMenuOpen(false)}
      />
      <Container maxWidth="lg" className={classes.mobileNavContainer}>
        <List>
          {navLinks.map(({ name, link, subMenuItems }) => {
            if (subMenuItems) {
              return (
                <CollapseNavMenu
                  name={name}
                  setMobileMenuOpen={setMobileMenuOpen}
                  subMenuItems={subMenuItems}
                />
              );
            }

            return (
              <NavItem
                key={`mobile=${name}`}
                setMobileMenuOpen={setMobileMenuOpen}
                name={name}
                link={link}
              />
            );
          })}
        </List>
        <Button onClick={handleContactClick} variant="outlined" color="primary">
          Contact Us
        </Button>
      </Container>
    </div>
  );
}
