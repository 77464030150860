import { makeStyles, Typography } from "@material-ui/core";
import { ReactElement } from "react";
import { Slide } from "react-awesome-reveal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "7rem 0",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3rem 0",
    },
  },
  image: {
    width: 550,
    height: 400,
    [theme.breakpoints.down("md")]: {
      marginBottom: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      height: 250,
    },
  },
  textContainer: {
    width: 550,
    [theme.breakpoints.down("sm")]: {
      width: 400,
      marginBottom: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
    },
  },
  header: {
    fontWeight: 300,
    marginBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },
  textContent: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      textAlign: "center",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

interface Props {
  header: string;
  textContent: string;
  imageUrl: string;
  imageAlt: string;
  buttons: ReactElement;
  reverse?: boolean;
}

export default function ShowcaseBanner({
  header,
  textContent,
  imageUrl,
  imageAlt,
  buttons,
  reverse,
}: Props): ReactElement<Props> {
  const classes = useStyles();

  // Add react elements to array and reverse if flag is set
  const elements = [
    <div key={`imagebanner-${header}`}>
      <img className={classes.image} src={imageUrl} alt={imageAlt} />
    </div>,
    <div key={`textcontent-${header}`} className={classes.textContainer}>
      <Typography align="center" className={classes.header} variant="h3">
        {header}
      </Typography>
      <Typography
        className={classes.textContent}
        color="textPrimary"
        variant="subtitle1"
      >
        {textContent}
      </Typography>
      <div className={classes.buttonContainer}>{buttons}</div>
    </div>,
  ];

  if (reverse) {
    elements.reverse();
  }

  return (
    <div className={classes.root}>
      <Slide direction="left" triggerOnce>
        {elements[0]}
      </Slide>
      <Slide direction="right" triggerOnce>
        {elements[1]}
      </Slide>
    </div>
  );
}
