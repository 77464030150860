import { makeStyles, Typography } from "@material-ui/core";
import { ReactElement } from "react";
import { portfolioItems } from "../../portfolioData";

const useStyles = makeStyles((theme) => ({
  item: {
    textAlign: "center",
    width: 500,
    margin: "2rem 0",
    [`& h5`]: {
      fontWeight: 400,
      margin: "2rem 0",
    },
    [`& p`]: {
      fontWeight: 200,
      marginBottom: "1rem",
      minHeight: "7rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
    },
  },
  imageBackground: {
    width: 500,
    height: 330,
    backgroundSize: "cover",
    backgroundPosition: "center",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      width: 300,
      height: 200,
    },
  },
}));

export default function PortfolioList(): ReactElement {
  const classes = useStyles();

  return (
    <>
      {portfolioItems.map(({ title, headingImageUrl, textContent }) => (
        <div className={classes.item} key={title}>
          <div
            style={{ backgroundImage: `url("${headingImageUrl}")` }}
            className={classes.imageBackground}
          ></div>
          <Typography component="h5" color="textPrimary" variant="h5">
            {title}
          </Typography>
          <Typography color="textSecondary" variant="subtitle1">
            {textContent}
          </Typography>
        </div>
      ))}
    </>
  );
}
