import { Container, makeStyles, Typography } from "@material-ui/core";
import { ReactElement } from "react";
import { Helmet } from "react-helmet";
import TopTitle from "../../components/TopTitle";

const useStyles = makeStyles((theme) => ({
  secondBanner: {
    padding: "7rem 0",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "4rem 1rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "4rem 2rem",
    },
  },
  image: {
    backgroundImage:
      'url("https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/fgaAbout.jpeg")',
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: 600,
    height: 600,
    [theme.breakpoints.down("md")]: {
      width: 400,
      height: 400,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      height: 300,
    },
  },
  secondBannerTextContent: {
    maxWidth: 600,
  },
}));

export default function AboutPage(): ReactElement {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>About | FGA Group Ltd</title>
      </Helmet>
      <TopTitle title="About Us" />
      <Container maxWidth="lg" className={classes.secondBanner}>
        <div className={classes.image} />
        <div className={classes.secondBannerTextContent}>
          <Typography color="textPrimary" component="h6" variant="subtitle1">
            FGA Group is a family business based in Northamptonshire, with a
            national reputation for the design and installation of premium steel
            frame buildings. By choosing us, you benefit from years of industry
            experience, specialist knowledge and a top quality product that
            enables expansion and diversification across a range of sectors.
            <br />
            <br />
            Our teams are passionate about delivering uncompromising quality via
            a collaborative and proactive approach. Certainty of delivery and
            quality is our hallmark and sits at the core of our business ethos
            and everything we do.
            <br />
            <br />
            Every project that we work on is headed by our directors, who
            assesses your site, work out the best option to suit your unique
            requirements, and ensures that every stage of design and
            construction of your steel frame buildings receives incredible
            attention to detail.
            <br />
            <br />
            This comprehensive and dedicated service has resulted in FGA Group
            delivering the finest steel buildings to sites across the UK, used
            for everything from agricultural livestock housing, grain stores,
            equestrian riding areas, stables, industrial & commercial buildings.
          </Typography>
        </div>
      </Container>
    </>
  );
}
