import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  ChangeEvent,
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";

interface ContactValue {
  value: string;
  errorText: string;
}

interface Props {
  setSent: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles({
  nameGroup: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: "1rem",
  },
  nameField: {
    minWidth: 300,
    marginTop: "0.5rem",
  },
  fullWidthField: {
    margin: "1rem 0",
  },
});

export default function ContactForm(): ReactElement<Props> {
  const classes = useStyles();

  const handleSetError = (
    setContact: Dispatch<SetStateAction<ContactValue>>,
    errorText: string
  ) =>
    setContact((contact) => ({
      ...contact,
      errorText,
    }));

  const [editStarted, setEditStarted] = useState(false);

  const [firstName, setFirstName] = useState<ContactValue>({
    value: "",
    errorText: "",
  });

  useEffect(() => {
    if (editStarted && firstName.value === "") {
      handleSetError(setFirstName, "Please provide a first name");
    } else {
      handleSetError(setFirstName, "");
    }
  }, [firstName.value]);

  const [lastName, setLastName] = useState<ContactValue>({
    value: "",
    errorText: "Please provide a last name",
  });

  useEffect(() => {
    if (editStarted && lastName.value === "") {
      handleSetError(setLastName, "Please provide a last name");
    } else {
      handleSetError(setLastName, "");
    }
  }, [lastName.value]);

  const [email, setEmail] = useState<ContactValue>({
    value: "",
    errorText: "",
  });

  useEffect(() => {
    if (editStarted && email.value === "") {
      handleSetError(setEmail, "Please provide a email address");
    } else {
      handleSetError(setEmail, "");
    }
  }, [email.value]);

  const [contactNumber, setContactNumber] = useState<ContactValue>({
    value: "",
    errorText: "Please provide a correct contact number",
  });

  const [subject, setSubject] = useState<ContactValue>({
    value: "",
    errorText: "Please provide a subject",
  });

  const [message, setMessage] = useState<ContactValue>({
    value: "",
    errorText: "Please provide a message",
  });

  useEffect(() => {
    if (editStarted && message.value === "") {
      handleSetError(setMessage, "Please provide a message");
    } else {
      handleSetError(setMessage, "");
    }
  }, [message.value]);

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: unknown }
    >,
    setContact: Dispatch<SetStateAction<ContactValue>>
  ) => {
    if (!editStarted) {
      setEditStarted(true);
    }
    setContact((contact) => ({
      ...contact,
      value: event.target.value as string,
    }));
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdWZsMkAAAAAP7Ymk_6xSMn_3ngN73FucMTGr5y"
      container={{
        element: "#contact-section",
        parameters: { badge: "bottomright" },
      }}
    >
      <form
        id="contact-section"
        action="https://getform.io/f/c6967f68-9a20-4e61-97c2-4c1d0ef30d43"
        method="POST"
      >
        <FormControl fullWidth>
          <FormLabel required>Name</FormLabel>
          <FormGroup className={classes.nameGroup} row>
            <TextField
              name="firstName"
              className={classes.nameField}
              required
              helperText={firstName.errorText}
              placeholder="First Name"
              variant="outlined"
              onChange={(event) => handleChange(event, setFirstName)}
              value={firstName.value}
              error={Boolean(firstName.errorText)}
            />
            <TextField
              name="lastName"
              className={classes.nameField}
              required
              helperText={lastName.errorText}
              placeholder="Last Name"
              variant="outlined"
              onChange={(event) => handleChange(event, setLastName)}
              value={lastName.value}
              error={Boolean(lastName.errorText)}
            />
          </FormGroup>
        </FormControl>
        <TextField
          name="email"
          className={classes.fullWidthField}
          label="Email Address"
          fullWidth
          required
          variant="outlined"
          onChange={(event) => handleChange(event, setEmail)}
          value={email.value}
          type="email"
        />
        <TextField
          name="number"
          className={classes.fullWidthField}
          label="Contact Number"
          fullWidth
          variant="outlined"
          onChange={(event) => handleChange(event, setContactNumber)}
          value={contactNumber.value}
        />
        <FormControl
          variant="outlined"
          className={classes.fullWidthField}
          fullWidth
        >
          <InputLabel required>Subject</InputLabel>
          <Select
            name="subject"
            fullWidth
            onChange={(event) => handleChange(event, setSubject)}
            value={subject.value}
          >
            <MenuItem value="Agricultural Buildings">
              Agricultural Buildings
            </MenuItem>
            <MenuItem value="Commerical Buildings">
              Commerical Buildings
            </MenuItem>
            <MenuItem value="Grain Driers">Grain Driers</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
        <TextField
          name="message"
          variant="outlined"
          multiline
          rows={6}
          fullWidth
          label="Message"
          className={classes.fullWidthField}
          onChange={(event) => handleChange(event, setMessage)}
          value={message.value}
          error={Boolean(message.errorText)}
          helperText={message.errorText}
          required
        />
        <input type="hidden" id="captchaResponse" name="g-recaptcha-response" />
        {/* <!-- add hidden Honeypot input to prevent spams --> */}
        <input
          type="hidden"
          name="_gotcha"
          style={{ display: "none !important" }}
        />
        <GoogleReCaptcha
          onVerify={(token) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            document.getElementById("captchaResponse").value = token;
          }}
        />
        <Button size="large" variant="outlined" type="submit">
          Send Message
        </Button>
      </form>
    </GoogleReCaptchaProvider>
  );
}
