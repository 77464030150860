import TopBanner from "../../components/TopPhotoBanner";
import { ReactElement } from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import MultiGalleryCarousel from "../../components/MultiGalleryCarousel";
import { BuildingImages } from "../../types";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "5rem 0",
    [theme.breakpoints.down("md")]: {
      padding: "1rem 2rem",
    },
  },
  title: {
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  subTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
}));

const buildingImages: BuildingImages[] = [
  {
    title: "HATO",
    subtitle:
      "Install of new 100mm Kingspan composite panels, associated flashings, purlins & new aluminium gutters & downpipes",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/Image+1.jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/e733349e-c33d-440c-88be-22333a7c2909.JPG",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/Image+(00A).jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/Image+(00C).jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/Image+2.jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/Image+3.jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/Image+4.jpeg",
    ],
  },
  {
    title: "Autospares",
    subtitle: "48m x 20m x 6m Commercial Building in Northamptonshire",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Autospares/1665630932892.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Autospares/1665630933613.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Autospares/Image+1.jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Autospares/Image.jpeg",
    ],
  },
  {
    title: "Bell Plantation",
    subtitle: "37m x 24m x 5m Commercial Building in Northamptonshire",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Bell+Plantation/Image+(004).jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Bell+Plantation/Image.jpeg",
    ],
  },
  {
    title: "Bespoke Office",
    subtitle: "Bespoke office building for client in Northamptonshire",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/commercial-building1.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/commercial-building2.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/commercial3.jpeg",
    ],
  },
  {
    title: "Dudley",
    subtitle: "62m x 25m x 5m Industrial Building in Birmingham",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Dudley/0ad094e0-5e2e-463a-b5d5-9c0872fedb78.JPG",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Dudley/81c746ba-0893-4cb0-8882-c6bc1c77d472.JPG",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Dudley/84ba9d1c-5ec5-41a8-8167-3ef3955c3349.JPG",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Dudley/b2c11122-ecf6-4bd5-b75d-e6efdc88bd03.JPG",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Dudley/cda5c6ae-68a3-43dd-897e-52415121c961.JPG",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Dudley/df838b94-ea77-4fe1-9a03-11dce6152885.JPG",
    ],
  },
  {
    title: "Shortwood",
    subtitle: "30m x 30m x 6m Multi use building in Northamptonshire",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Shortwood+Farm/1.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Shortwood+Farm/2.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Shortwood+Farm/3.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Shortwood+Farm/4.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Shortwood+Farm/5.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Shortwood+Farm/6.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Shortwood+Farm/7.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Shortwood+Farm/8.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Shortwood+Farm/9.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Shortwood+Farm/10.jpg",
    ],
  },
];

export default function CommercialBuildingPage(): ReactElement {
  const classes = useStyles();

  return (
    <>
      <TopBanner backgroundImage="https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Bell+Plantation/Image+(004).jpeg" />
      <Container className={classes.container} maxWidth="lg">
        <Typography className={classes.title} variant="h4">
          Commercial buildings for every kind of client, every size of budget.
        </Typography>
        <Typography className={classes.subTitle} variant="subtitle1">
          We work with blue chip organisations to design and develop bespoke
          commercial buildings to suit the client’s needs. From insulated
          cladding, mezzanine floors, to bespoke curtain walling and glazing.
          With farmers diversifying we are seeing more and more demand for
          agricultural buildings being turned into commercial buildings.
        </Typography>
        <MultiGalleryCarousel buildingImages={buildingImages} />
      </Container>
    </>
  );
}
