import { Container, makeStyles, Typography } from "@material-ui/core";
import { ReactElement } from "react";

interface Props {
  backgroundImage?: string;
  header: string;
  button: ReactElement;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundSize: "cover",
    backgroundColor: theme.palette.secondary.main,
    padding: "15rem 0",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "12rem 0",
    },
  },
  header: {
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
}));

export default function Banner({
  backgroundImage,
  header,
  button,
}: Props): ReactElement<Props> {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={
        backgroundImage ? { backgroundImage: `url("${backgroundImage}")` } : {}
      }
    >
      <Container maxWidth="lg">
        <Typography
          className={classes.header}
          align="center"
          color="primary"
          variant="h3"
        >
          {header}
        </Typography>
        {button}
      </Container>
    </div>
  );
}
